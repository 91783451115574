import React from 'react';

const PrivacyPolicy = () => {
    const containerStyle = {
        backgroundColor: '#f8fafc',
        color: '#1f2937',
        padding: '2rem'
    };

    const headerStyle = {
        backgroundColor: '#dbeafe',
        padding: '1rem',
        borderRadius: '0.5rem',
        textAlign: 'center',
        marginBottom: '1.5rem',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
    };

    const titleStyle = {
        fontSize: '2rem',
        fontWeight: 'bold',
        color: '#3F5B81'
    };

    const contentStyle = {
        backgroundColor: '#ffffff',
        padding: '1.5rem',
        borderRadius: '0.5rem',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    };

    const sectionTitleStyle = {
        fontSize: '1.25rem',
        fontWeight: '600',
        marginTop: '1.5rem',
        marginBottom: '1rem',
        color: '#3F5B81'
    };

    const paragraphStyle = {
        marginBottom: '0.25rem'
    };

    const listStyle = {
        listStyleType: 'disc',
        paddingLeft: '1.5rem',
        color: '#374151',
    };

    const footerStyle = {
        backgroundColor: '#dbeafe',
        padding: '1rem',
        borderRadius: '0.5rem',
        textAlign: 'center',
        marginTop: '1.5rem',
        fontSize: '0.875rem',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
        color: '#1e40af'
    };

    const linkStyle = {
        color: '#2563eb',
        textDecoration: 'underline'
    };

    return (
        <div style={containerStyle}>
            <header style={headerStyle}>
                <h1 style={titleStyle}>Privacy Policy & Terms</h1>
            </header>
            <div style={contentStyle}>
                <p style={paragraphStyle}>This Privacy Policy describes Digital Biz Tech policies and procedures on the collection, and disclosure of your information when you use our service and tells you about your privacy rights and how the law protects you.</p>
                <p style={paragraphStyle}>We use your personal data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this Privacy Policy.</p>



                <h2 style={sectionTitleStyle}>Information Collection</h2>
                <p style={paragraphStyle}>We collect only the information necessary to provide and improve our services. This may include your name, email address, and phone number. We do not sell, rent, or share this information with any third parties.</p>

                <h2 style={sectionTitleStyle}>How We Use Your Information</h2>
                <p style={paragraphStyle}>The information collected is used solely for communicating with you as the intended party. We do not share your information with external parties for marketing or any other purposes.</p>
                <p style={paragraphStyle}>We may use personal data for the following purposes:</p>
                <ul style={listStyle}>
                    <li>To provide and maintain our service, including to monitor the usage of our service.</li>
                    <li>To manage your Account: to manage your registration as a user of the Service.</li>
                    <li>For the performance of a contract: development, compliance, and undertaking of contracts.</li>
                    <li>To contact you via email, telephone calls, SMS, or other forms of electronic communication.</li>
                    <li>To provide you with news, special offers, and general information about other goods and services.</li>
                    <li>To manage your requests to us.</li>
                </ul>

                <h2 style={sectionTitleStyle}>SMS Disclosure</h2>
                <p style={paragraphStyle}>By reaching us by SMS, you agree to receive recurring messages from Digital Biz Tech. Message and data rates may apply. Reply STOP to opt-out or HELP for more information.</p>
                <p style={paragraphStyle}>Phone numbers and SMS consent will not be shared with third parties or affiliates for marketing purposes.</p>

                <h2 style={sectionTitleStyle}>Disclosure of Your Information</h2>
                <p style={paragraphStyle}>We do not share any client data with third parties for marketing, promotional purposes, or any other purposes, except as required by law or with your explicit consent.</p>
                <p style={paragraphStyle}>We may disclose your personal information under the following limited circumstances:</p>
                <ul style={listStyle}>
                    <li>With your consent.</li>
                    <li>To enforce our Terms of Service.</li>
                    <li>To partners or affiliates under non-disclosure agreements.</li>
                    <li>In response to legal obligations like subpoenas or court orders.</li>
                    <li>To investigate, prevent, or take action regarding illegal activities or suspected fraud.</li>
                </ul>

                <h2 style={sectionTitleStyle}>International Data Transfers</h2>
                <p style={paragraphStyle}>Your Personal Information may be transferred and processed in locations outside your jurisdiction. We ensure your data is handled securely and in line with this Policy.</p>

                <h2 style={sectionTitleStyle}>Data Retention</h2>
                <p style={paragraphStyle}>We retain your Personal Information only as long as necessary to fulfill the purposes outlined in this Policy unless a longer retention period is required by law.</p>

                <h2 style={sectionTitleStyle}>Cookies and Tracking Technologies</h2>
                <p style={paragraphStyle}>Our Service may use cookies to enhance your experience. You can control the use of cookies through your browser settings.</p>

                <h2 style={sectionTitleStyle}>Your Choices</h2>
                <p style={paragraphStyle}>You have the right to access, correct, or delete your information. For any concerns, contact us at the details below.</p>

                <h2 style={sectionTitleStyle}>Policy Changes</h2>
                <p style={paragraphStyle}>We may update our privacy policy from time to time. Any changes will be communicated to you, and your continued use of our services implies your acceptance of the updated policy.</p>

                <h2 style={sectionTitleStyle}>Terms and conditions</h2>
            
                <ul style={listStyle}>
                    <li><b>Consent for SMS Communications</b>: Upon granting consent to receive SMS communications from our company, please be assured that your phone number and SMS consent will remain confidential. We will not sell or share your information with third parties or affiliates. Your phone number and consent will solely be used to facilitate communication with you and will not be disseminated for marketing purposes.</li>
                    <li><b>Types of SMS Communications</b>: After obtaining consent, the following types of messages may be sent to you, depending on your interactions with our services:
                        <ul style={listStyle}>
                            <li><b>Customers and Guests:</b> Updates related to orders, deliveries, or other relevant information.</li>
                            <li><b>Job Applicants:</b> Notifications regarding the status of your application.</li>
                            <li><b>Appointment Reminders:</b> Reminders about scheduled appointments or events.</li>
                            <li>Example Message:
                            "Hi [Customer Name], this is a reminder about your upcoming appointment with [Business Name] on [Date] at [Time]. We look forward to seeing you!" To opt out, reply "STOP" to unsubscribe. Message data rates may apply. Messaging frequency may vary. For assistance, reply "HELP.".</li>
                        </ul>
                    </li>
                    <li><b>Standard Messaging Disclosures</b>
                        <ul style={listStyle}>
                            <li>To opt out of SMS communications, simply reply with "STOP."</li>
                            <li>For assistance or inquiries, reply with "HELP."</li>
                            <li>Message and data rates may apply</li>
                        </ul>
                    </li>
                    <li>Sample response for "HELP" reply:
                    "For further assistance, please visit our website at https://www.digitalbiz.tech/ or contact us at 614-347-3250."</li>
                    <li><b>Message Frequency: </b>You may receive approximately 5-10 messages per day related to your requests. However, the frequency may vary depending on the nature of the communication.</li>
                </ul>

                <h2 style={sectionTitleStyle}>Opt-In Method</h2>
                <p>You may opt-in to receive SMS messages from Digital Biz Tech in the following ways:</p>
                <ul style={listStyle}>
                    <li>Verbally, during a conversation</li>
                    <li>By submitting an online form</li>
                </ul>

                <h2 style={sectionTitleStyle}>Opt-Out Method</h2>
                <p>You can opt-out of receiving SMS messages at any time. To do so, simply reply "STOP" to any SMS message you receive. Alternatively, you can contact us directly to request removal from our messaging list.</p>

                <h2 style={sectionTitleStyle}>Additional Options</h2>
                <p>If you do not wish to receive SMS messages, you can choose not to check the SMS consent box on our forms.</p>


                <h2 style={sectionTitleStyle}>Contact</h2>
                <p style={paragraphStyle}>If you have an inquiry or complaint about this privacy policy or our privacy practices, please contact us:</p>
                <p style={paragraphStyle}><strong>Digital Biz Tech</strong><br />
                565 Metro Pl S Ste 300, Dublin, OH 43017<br />
                Email: <a href="mailto:info@digitalbiz.tech" style={linkStyle}>info@digitalbiz.tech</a></p>
            </div>
            <footer style={footerStyle}>
                <p>Last updated: 12-24-2024</p>
            </footer>
        </div>
    );
};

export default PrivacyPolicy;
